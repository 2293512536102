import * as Sentry from "@sentry/gatsby";

import { CONFIG, Environment } from "./config";

export const initializeSentry = () => {
  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    environment: CONFIG.ENVIRONMENT,
    replaysSessionSampleRate:
      CONFIG.ENVIRONMENT === Environment.Production ? 0.1 : 0,
    replaysOnErrorSampleRate: 1.0,
    attachStacktrace: true,
    integrations: [
      Sentry.replayIntegration({
        maskAllInputs: true,
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
};
