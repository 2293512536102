import { z } from "zod";

export enum Environment {
  Development = "development",
  Staging = "staging",
  Production = "production",
}

const configSchema = z.object({
  ENVIRONMENT: z.nativeEnum(Environment),
  GOOGLE_CLIENT_ID: z.string(),
  SENTRY_DSN: z.string(),
});

type Config = z.infer<typeof configSchema>;

const getConfig = (): Config => {
  try {
    const parsedConfig = configSchema.parse({
      ENVIRONMENT: process.env.GATSBY_ENVIRONMENT,
      GOOGLE_CLIENT_ID: process.env.GATSBY_GOOGLE_CLIENT_ID,
      SENTRY_DSN: process.env.GATSBY_SENTRY_DSN,
    });

    return parsedConfig;
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error("Could not parse process.env: ", error.errors);
    }
    throw error;
  }
};

export const CONFIG = getConfig();
