/** @jsx jsx */
import { globalHistory, Location } from "@reach/router";
import { ReactElement } from "react";
import { jsx } from "theme-ui";
import { QueryParamProvider } from "use-query-params";

// See https://www.gatsbyjs.org/docs/adding-redux-store/

export default function wrapWithProviders({
  element,
}: {
  element: ReactElement;
}): ReactElement {
  return (
    <Location>
      {({ location }) => (
        <QueryParamProvider location={location} reachHistory={globalHistory}>
          {element}
        </QueryParamProvider>
      )}
    </Location>
  );
}
