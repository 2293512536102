import { theme } from "@alch/ui";
import { merge } from "theme-ui";

export default merge(theme as any, {
  styles: {
    root: {
      backgroundColor: "#f5f6f7",
      a: {
        textDecoration: "none",
      },
    },
  },
});
