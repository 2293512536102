import "./src/styles/global.css";

import { initializeSentry } from "./src/config/sentry";
import wrapWithProviders from "./src/util/wrapWithProviders";

// See https://www.gatsbyjs.org/docs/adding-redux-store/
export const wrapRootElement = wrapWithProviders;

export const onClientEntry = () => {
  initializeSentry();
};
