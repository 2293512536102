// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-change-email-tsx": () => import("./../../../src/pages/change-email.tsx" /* webpackChunkName: "component---src-pages-change-email-tsx" */),
  "component---src-pages-confirm-email-tsx": () => import("./../../../src/pages/confirm-email.tsx" /* webpackChunkName: "component---src-pages-confirm-email-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invalid-redirect-tsx": () => import("./../../../src/pages/invalid-redirect.tsx" /* webpackChunkName: "component---src-pages-invalid-redirect-tsx" */),
  "component---src-pages-invite-tsx": () => import("./../../../src/pages/invite.tsx" /* webpackChunkName: "component---src-pages-invite-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-new-password-tsx": () => import("./../../../src/pages/new-password.tsx" /* webpackChunkName: "component---src-pages-new-password-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-sso-login-tsx": () => import("./../../../src/pages/sso-login.tsx" /* webpackChunkName: "component---src-pages-sso-login-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-verify-tsx": () => import("./../../../src/pages/verify.tsx" /* webpackChunkName: "component---src-pages-verify-tsx" */)
}

